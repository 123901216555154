<template>
  <div :style="this.CSS" :class="CSSClasses" v-show="!isHidden">
    <el-form-item
      ref="form_item"
      :prop="name"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'change' },
      ]"
    >
      <el-checkbox-group
        @change="change"
        :disabled="_isReadonly"
        :name="name"
        :size="size"
        v-model="localModel"
      >
        <el-checkbox
          v-for="(item, index) in options"
          :key="index"
          :label="item.value"
          :is="type === 'basic' ? 'el-checkbox' : 'el-checkbox-button'"
        >{{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import RequiredMixin from '@/components/InterfaceEditor/components/required_properties_mixin'

export default {
  name: 'a-checkbox',
  mixins: [mixin, VisibleMixin, RequiredMixin],
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {
      }
    }
  },
  props: {
    value: {
      frozen: true
    },
    model: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    width: {
      type: String,
      description: 'width'
    },
    type: {
      type: String,
      description: 'view',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },
    defaultCheck: {
      type: Boolean,
      description: 'default_check',
      frozen: true
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'Лэйбл', value: 'Значение' }]
      },
      editor: 'Options'
    }
  },
  data () {
    return {
      localModel: this.$attrs.value || []
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    options: {
      handler (value) {
        if (!value[0].default) {
          this.$emit('change-property', { name: 'defaultCheck', value: false })
        }
      },
      deep: true
    },
    value (val) {
      if ((this.localModel || '').toString() !== (val || '').toString()) {
        this.localModel = (val || '').toString().split(',')
        this.parseValue()
      }
    }
  },
  created () {
    // defaultCheck для сохранения старого функционала
    if (this.defaultCheck) {
      this.localModel = [this.options[0].value]
    }

    let defaultCheckox = this.options.filter(item => item.default).map(item => item.value)
    if (defaultCheckox.length) {
      this.localModel = [...defaultCheckox]
    }
    this.parseValue()
  },
  mounted () {
    if (this.localModel.length) {
      this.$emit('input', this.localModel.toString())
    }
  },
  methods: {
    parseValue () {
      if (typeof this.localModel === 'string') {
        let parsed = this.localModel
        try {
          parsed = JSON.parse(parsed)
        } catch (e) {
        }
        if (parsed instanceof Array) {
          this.localModel = parsed.map((item) => (item.id || item) + '')
        }
      }
    },
    change (event) {
      this.$emit('input', event.toString())
      if (this.name) {
        this.$refs.form_item?.form?.validateField(this.name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
